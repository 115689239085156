
















































































































































































import { Component, Vue } from "vue-property-decorator";
import { apiSettlementLists } from "@/api/order";
import SettlementEditor from "@/views/finance/editor/settlementEditor.vue";
import { copyObject, deepClone } from "@/utils/util";
import { RequestPaging } from "@/utils/util";
import { apiShopLists } from "@/api/shop";
import LsPagination from "@/components/ls-pagination.vue";

@Component({
    components: {
        SettlementEditor,
        LsPagination,
    },
})
export default class settlement_statistics extends Vue {
    list = [];
    count = 0;
    search: any = {};

    pager = new RequestPaging();
    shopLists = [];
    apiSettlementLists = apiSettlementLists;
    form = {
        sn: "", //否	string	订单信息
        type: "",
        status: "",
        settlement_month: "",
        shop_id: "",
    };
    created() {
        const s = this.$route.query.s || null;
        this.getShopLists();
        if (s) {
            this.form.status = s;
        }
        this.getList();
    }

    pageChange(page: any) {
        this.pager.page = page;
        this.getList();
    }

    sizeChange(size: any) {
        this.pager.page = 1;
        this.pager.size = size;
        this.getList();
    }

    // 重置搜索
    reset() {
        Object.keys(this.form).map((key) => {
            this.$set(this.form, key, "");
        });
        this.getList();
    }

    // 获取商城列表
    getShopLists(): void {
        apiShopLists({ page_type: 0, app_id: 11 }).then((res) => {
            this.shopLists = res.lists;
        });
    }

    // 获取订单信息
    getList(page?: number) {
        page && (this.pager.page = page);
        this.pager
            .request({
                callback: apiSettlementLists,
                params: {
                    ...this.form,
                },
            })
            .then((res) => {
                this.list = res.lists;
                this.count = res.count;
            });
    }

    openSettlement(item: any, type: number) {
        let SettlementEditor = <SettlementEditor>this.$refs.SettlementEditor;
        switch (type) {
            case 1:
                SettlementEditor.settlementVisible = true;
                SettlementEditor.type = type;
                SettlementEditor.info = copyObject(item);
                break;
            case 2:
                SettlementEditor.settlementVisible = true;
                SettlementEditor.type = type;
                SettlementEditor.info = copyObject(item);
                break;
        }
    }
}
